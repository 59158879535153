import React from "react";

const Superhost = ({ className }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 232.4 68.1"
    className={className}
  >
    <path
      className="st0"
      d="M32.3,23.9c-1.8,0.3-2.9,2-2.6,3.7c0.3,1.8,2,2.9,3.7,2.6c1.8-0.3,2.9-2,2.6-3.7C35.7,24.8,34,23.6,32.3,23.9z
	 M6.3,8c-4.2,0.7-6.9,4.7-6.2,8.8l7.9,45c0.7,4.2,4.7,6.9,8.8,6.2l3.7-0.7c11.2-2,16.5-6.8,13.8-21.9c-1.2,0-2.5-0.2-3.6-0.6
	c-0.9,1.5-2,3.2-3.9,5.9c-1.6-3.4-3.4-8.5-3.1-11c0.2-1.9,1.1-4.9,3.1-5.7c-0.6-1.4-1-2.9-1.2-4.4c-1.1-6.4,0.5-12.9,4.5-18
	c5.5,3.4,9.3,9,10.4,15.4c0.3,1.5,0.4,3,0.3,4.5c2.1,0.1,4.1,2.6,4.9,4.3c1.1,2.3,1.1,7.7,0.8,11.4c-2.7-1.9-4.4-3.1-5.7-4.2
	c-1,0.8-2,1.4-3.2,1.8c2.7,15.1,9.2,17.8,20.5,15.8l3.7-0.7c4.2-0.7,6.9-4.7,6.2-8.8l-7.9-45c-0.7-4.2-4.7-6.9-8.8-6.2L6.3,8z
	 M73.7,34.1c0,1.8-0.1,3.7,0,5.5s1.1,3,2.9,3.3c2,0.4,3.2-0.7,4-2.4c1.1-2.7,0.5-5.2-1.1-7.5c-2-2.9-4.2-5.6-5.6-8.7
	c-2.2-4.7,0.4-8.9,5.5-9.5c4.1-0.5,6.6,1.5,6.6,5.7c0,1.4-0.4,2.8-0.6,4.2l-0.8-0.1c0.1-1.3,0.2-2.7,0.3-4c0.1-3-1-4.7-3.1-5
	s-3.9,1-4.1,4c-0.1,2,0.6,4.2,1.6,6.1c1.5,2.9,4,5.4,5.4,8.3c2.5,5.4-0.5,9.8-6.4,9.9c-3.2,0.1-4.7-1-5.2-4.2
	C72.8,37.9,73,35.9,73,34C73.1,34.1,73.4,34.1,73.7,34.1 M232.4,30.6c-0.7,2.1-1.4,3.9-2,5.6s-1.3,3.2-1.9,4.4
	c-0.7,1.2-1.4,2.2-2.1,2.8c-0.8,0.7-1.6,1-2.6,1s-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-1.3-2.7c-0.3-1.1-0.4-2.4-0.5-3.9
	c0-1.4,0-2.8,0.1-4.3c-0.5,0.6-1,1.3-1.7,1.9c-0.7,0.6-1.4,1.2-2.2,1.7s-1.7,0.9-2.7,1.2c0.5,0.5,0.8,1,1.1,1.5s0.5,1,0.5,1.6
	s-0.2,1.2-0.5,1.8s-0.8,1.1-1.5,1.6c-0.6,0.5-1.4,0.9-2.3,1.2c-0.9,0.3-2,0.4-3.1,0.4c-1.2,0-2.1-0.2-3-0.5
	c-0.8-0.3-1.4-0.7-1.9-1.2s-0.7-1.1-0.8-1.7c-0.1-0.6,0.1-1.2,0.4-1.8c0.4-0.6,0.9-1.2,1.7-1.7s1.8-0.9,3.1-1.3l-1.5-1.2
	c-0.5-0.4-0.9-0.8-1.4-1.3c-0.4-0.5-0.7-1-1-1.5c-0.1-0.2-0.1-0.4-0.2-0.5c-0.3,0.4-0.6,0.9-1,1.4c-0.6,0.7-1.2,1.5-2,2.2
	s-1.6,1.3-2.6,1.8c-0.3,0.9-0.6,1.7-1.1,2.4c-0.4,0.8-0.9,1.4-1.5,2s-1.2,1-1.9,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
	c-1.8,0-3.2-0.5-4.3-1.4s-1.8-2-2.2-3.4c-0.2-0.7-0.3-1.3-0.4-2c-0.5,1.2-1,2.3-1.6,3.2c-0.7,1.2-1.5,2.1-2.3,2.8s-1.8,1-2.8,1
	c-0.9,0-1.6-0.2-2.1-0.7c-0.5-0.4-0.8-1-0.9-1.8c-0.2-0.7-0.2-1.6-0.1-2.5c0.1-0.9,0.2-1.9,0.4-2.9s0.4-1.9,0.6-2.9
	c0.2-0.9,0.4-1.8,0.5-2.5c0.1-0.7,0.1-1.3,0-1.8c-0.1-0.4-0.4-0.7-0.8-0.7c-0.6,0-1.1,0.3-1.7,0.9c-0.6,0.6-1.1,1.3-1.6,2.3
	c-0.5,1-0.9,2-1.3,3.1c-0.4,1.2-0.7,2.3-1,3.4c-0.2,1.1-0.4,2.2-0.5,3.1c-0.1,1-0.1,1.8,0.1,2.3c0.1,0.2-0.2,0.5-0.6,0.7
	c-0.5,0.2-1,0.3-1.4,0.3c-0.7,0-1.2-0.4-1.6-1.1c-0.4-0.7-0.7-1.6-0.9-2.8s-0.3-2.5-0.3-4c0-0.9,0-1.9,0.1-2.9
	c-0.3,0.8-0.6,1.5-0.9,2.2c-0.7,1.6-1.5,3-2.2,4.2c-0.8,1.2-1.5,2.1-2.3,2.7c-0.8,0.6-1.6,0.9-2.5,0.9c-2.2,0-3.7-1.1-4.5-3.4
	c-0.7-2.2-0.6-5.7,0.5-10.3c-0.4,0.4-0.9,0.8-1.3,1c-0.5,0.3-0.9,0.4-1.4,0.4c-0.4,0-0.7,0-1.1-0.1c-0.3,0.6-0.6,1.3-1,2.1
	c-0.5,1-1.1,2-1.9,3.1c-0.7,1.1-1.6,2.1-2.6,3.1c-1,0.9-2,1.7-3.2,2.3s-2.4,0.9-3.7,0.9c-1.7,0-3-0.4-4-1.2s-1.7-1.8-2.1-3
	c-0.4-1.2-0.5-2.5-0.3-4c0.1-0.8,0.3-1.5,0.6-2.2c-0.9,0.7-2.1,1.6-3.5,2.4c0,0.9-0.1,1.9-0.3,2.8c-0.2,0.9-0.6,1.8-1.1,2.6
	s-1.2,1.4-2,1.9s-1.9,0.7-3.1,0.7c-0.3,0-0.6,0-1-0.1c-0.4,0-0.7-0.2-1.1-0.3c-0.3-0.2-0.6-0.4-0.8-0.8c-0.2-0.3-0.3-0.8-0.3-1.3
	s0.1-1,0.4-1.5s0.7-0.9,1.1-1.3s1-0.7,1.6-1.1c0.6-0.3,1.2-0.6,1.7-0.9c0.2-0.8,0.3-1.6,0.4-2.4c0.1-0.8,0.1-1.5,0-2
	c-0.1-0.6-0.2-1.1-0.4-1.5c-0.2-0.4-0.4-0.6-0.7-0.6c-1.1,0-2.1,0.6-3,1.8c-0.9,1.2-1.7,3-2.4,5.3c-0.6,2.3-1.1,5.2-1.3,8.6
	c-0.2,3.4-0.1,7.3,0.3,11.6c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.3s-0.4,0.2-0.7,0.2c-0.3,0-0.5,0.1-0.7,0.1
	c-0.5,0-1-0.4-1.3-1.1c-0.4-0.7-0.7-1.7-0.9-2.9c-0.3-1.2-0.5-2.6-0.6-4.2c-0.2-1.6-0.3-3.2-0.3-4.9c-0.1-1.7-0.1-3.3-0.1-5
	s0.1-3.2,0.1-4.6v-0.4c-0.1,0.5-0.3,1-0.4,1.4c-0.5,1.7-1,3.1-1.6,4.2c-0.6,1.2-1.2,2-1.8,2.6c-0.6,0.6-1.4,0.9-2.2,0.9
	c-1.4,0-2.4-0.4-3.1-1.1c-0.7-0.7-1.1-1.9-1.3-3.4c-0.7,1.7-1.4,2.9-2.3,3.5s-1.9,1-3,1c-1.6,0-2.8-0.3-3.6-1
	c-0.8-0.7-1.4-1.7-1.7-3c-0.3-1.3-0.4-3-0.2-5s0.5-4.4,0.9-7.1c0.4-0.5,0.9-0.9,1.5-1c0.7-0.1,1.3-0.1,1.9,0.2
	c0.3,0.1,0.6,0.3,0.7,0.6c0.2,0.2,0.2,0.5,0.3,0.8v1c0,0.4-0.1,0.8-0.1,1.3c-0.1,1.2-0.2,2.5-0.3,3.8c-0.1,1.3-0.2,2.6-0.2,3.7
	s0.1,2.1,0.4,2.8c0.2,0.7,0.7,1.1,1.5,1.1c0.3,0,0.7-0.1,1.1-0.3s0.8-0.6,1.1-1.1c0.4-0.5,0.7-1.2,1.1-2.1c0.3-0.9,0.6-1.9,0.8-3.1
	c0.1-1.2,0.2-2.5,0.4-3.9c0.2-1.4,0.4-2.9,0.7-4.5c0.3-0.5,0.9-0.9,1.5-1c0.7-0.1,1.3-0.1,1.9,0.2c0.3,0.1,0.6,0.3,0.7,0.6
	c0.2,0.2,0.2,0.5,0.3,0.8v1c0,0.4-0.1,0.8-0.1,1.3c-0.1,1.2-0.2,2.5-0.3,3.9c-0.1,1.4-0.2,2.8-0.2,4c-0.1,1.2,0,2.2,0.1,3.1
	c0.1,0.8,0.3,1.2,0.7,1.2c0.3,0,0.6-0.2,1-0.7c0.3-0.4,0.7-1,1-1.7s0.7-1.5,1-2.4s0.6-1.8,0.9-2.6c0.3-0.9,0.6-1.7,0.8-2.4
	c0.2-0.8,0.5-1.4,0.6-1.8c0.1-0.5,0.1-0.9,0.2-1.1c0.2-0.9,0.7-1.6,1.3-2c0.6-0.4,1.2-0.6,1.8-0.7c0.6,0,1.1,0.1,1.6,0.4
	c0.4,0.3,0.6,0.7,0.6,1.2c-0.1,0.4-0.1,0.9-0.2,1.2c-0.1,0.4-0.1,0.9-0.2,1.4s-0.1,1.2-0.2,2s-0.2,1.8-0.2,3.1
	c0.3-0.9,0.7-1.9,1.1-2.8c0.5-0.9,1-1.7,1.6-2.5c0.6-0.7,1.3-1.3,2.1-1.8c0.8-0.4,1.6-0.7,2.6-0.7c0.6,0,1.1,0.2,1.6,0.5
	s0.9,0.8,1.3,1.3s0.7,1.2,1,1.8c0.3,0.7,0.5,1.4,0.6,2.1c0.6-0.4,1.1-0.7,1.6-1c0.5-0.3,0.9-0.6,1.3-0.9s0.8-0.6,1.1-0.9l1-1h0.1
	c0.6-0.8,1.2-1.5,2.1-2c1.2-0.8,2.5-1.2,4-1.2c1.3,0,2.2,0.2,2.9,0.7s1.1,1.1,1.2,1.9c0.2,0.7,0.1,1.6-0.2,2.4
	c-0.3,0.9-0.8,1.7-1.4,2.5c-0.6,0.8-1.4,1.4-2.3,2c-0.9,0.5-1.9,0.8-2.9,0.9c-0.1,0.6,0,1.1,0.1,1.6s0.2,0.9,0.5,1.3
	c0.2,0.4,0.5,0.7,0.9,0.9s0.9,0.3,1.4,0.3c0.9,0,1.8-0.4,2.8-1.2s1.9-1.7,2.8-2.8s1.8-2.3,2.6-3.7c0.5-0.8,0.9-1.5,1.3-2.3
	c-0.2-0.1-0.3-0.1-0.5-0.1c-0.6-0.3-1.1-0.7-1.5-1.2s-0.6-1-0.7-1.5c-0.1-0.6-0.2-1.1-0.2-1.6s0-1,0.1-1.4s0.2-0.7,0.2-0.8
	c0.2-0.4,0.4-0.7,0.8-0.9c0.3-0.2,0.7-0.3,1.1-0.4c0.4,0,0.9,0,1.3,0.1s0.8,0.2,1.1,0.4c0.3,0.1,0.6,0.3,0.8,0.5s0.2,0.4,0.2,0.5
	c-0.1,0.1-0.2,0.3-0.3,0.7c-0.1,0.3-0.3,0.7-0.4,1c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.3,0,0.6,0.2,0.9
	c0.1,0.2,0.4,0.3,0.7,0.3c0.4,0,0.7-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.3,0.5-0.5,0.7-0.8s0.4-0.6,0.5-0.8
	c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1,0.1-0.1,0.1-0.1c0.3-0.4,0.8-0.7,1.3-0.9c0.5-0.2,1.2-0.1,2,0.2c0.6,0.2,1,0.5,1.3,0.8
	c0.2,0.3,0.4,0.6,0.4,1s-0.1,0.8-0.2,1.2c-0.2,0.5-0.3,1-0.5,1.5c-0.7,2.3-1.1,4.2-1.4,5.6c-0.3,1.5-0.4,2.6-0.4,3.4
	c0,0.8,0.2,1.4,0.4,1.7c0.3,0.3,0.6,0.5,0.9,0.5c0.5,0,0.9-0.2,1.4-0.7c0.5-0.4,1-1,1.4-1.7c0.5-0.7,0.9-1.5,1.4-2.4
	s0.9-1.8,1.2-2.6c0.4-0.9,0.7-1.7,1-2.4c0.2-0.6,0.4-1.1,0.6-1.6L164,27c0.2-1.6,0.4-3.1,0.6-4.5c0.2-1.4,0.4-2.6,0.6-3.6
	c0.2-1,0.3-1.7,0.5-2.1c0.3-0.9,0.7-1.6,1.3-2s1.2-0.6,1.8-0.7c0.6,0,1.1,0.1,1.6,0.4c0.4,0.3,0.6,0.7,0.5,1.2c-0.4,2.2-0.7,4.2-1,6
	c-0.3,1.8-0.5,3.4-0.8,4.9c-0.2,1.5-0.4,2.9-0.5,4.2c-0.1,1.3-0.2,2.6-0.3,3.8c0.3-0.9,0.7-1.9,1.1-2.8c0.4-0.9,0.9-1.7,1.5-2.5
	c0.6-0.7,1.2-1.3,2-1.8c0.7-0.4,1.6-0.7,2.5-0.7c1.2,0,2.1,0.2,2.7,0.6c0.7,0.4,1.1,1,1.4,1.7c0.3,0.7,0.4,1.5,0.4,2.4
	s-0.1,1.8-0.3,2.7l-0.6,2.7c-0.2,0.9-0.4,1.7-0.5,2.4s-0.2,1.3-0.2,1.7s0.2,0.6,0.5,0.6s0.6-0.2,1-0.7c0.4-0.4,0.8-1,1.2-1.7
	s0.8-1.5,1.2-2.4s0.8-1.7,1.1-2.6c0.2-0.5,0.4-0.9,0.6-1.4c0.3-1,0.6-1.9,1.1-2.8c0.7-1.4,1.7-2.5,3-3.4s2.7-1.4,4.4-1.4
	c1.1,0,2.1,0.3,2.9,0.9c0.8,0.6,1.4,1.4,1.8,2.4c0.4,1,0.7,2.1,0.8,3.3s0,2.5-0.2,3.7c0.8-0.4,1.4-0.9,2-1.4
	c0.6-0.6,1.1-1.1,1.5-1.6s0.7-1,1-1.5c0.2-0.4,0.4-0.7,0.5-0.9c0.1-1.4,0.7-2.5,1.7-3.4c1.1-1,2.7-1.5,4.5-1.5
	c1.3,0,2.3,0.2,3.1,0.5s1.4,0.8,1.8,1.3c0.4,0.5,0.6,1.2,0.6,1.9s-0.2,1.5-0.6,2.3c-0.1,0.2-0.2,0.4-0.4,0.4H213
	c-0.1,0-0.2-0.1-0.3-0.3c-0.1-0.1,0-0.2,0.1-0.4c0.2-0.3,0.3-0.7,0.4-1.2c0.1-0.5,0.1-1-0.1-1.5c-0.1-0.5-0.4-0.9-0.8-1.3
	s-1-0.5-1.7-0.5c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.8c-0.3,0.3-0.4,0.8-0.6,1.2c-0.1,0.5-0.2,0.9-0.2,1.4
	c0,0.6,0.1,1.1,0.4,1.6c0.3,0.5,0.7,1,1.1,1.4c0.4,0.5,0.9,0.9,1.5,1.3c0.5,0.4,1.1,0.8,1.6,1.3c0.8-0.2,1.6-0.5,2.4-0.9
	c0.8-0.4,1.5-0.9,2.2-1.5s1.2-1.1,1.8-1.7c0.5-0.6,0.9-1.2,1.2-1.7c0.1-1.2,0.3-2.4,0.5-3.7c0.3-1.7,0.5-3.4,0.8-5
	c-0.9,0-1.7,0-2.4-0.1h-1.6c-0.2,0-0.4,0-0.5-0.1s-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.1,0.5-0.1
	c0.4,0,0.9,0,1.7-0.1c0.8,0,1.6-0.1,2.6-0.1c0.2-0.8,0.3-1.5,0.5-2.2s0.3-1.4,0.4-2c0-0.4,0.2-0.8,0.7-1c0.4-0.2,0.9-0.4,1.5-0.4
	c0.5,0,1,0,1.5,0.2c0.5,0.1,0.7,0.4,0.8,0.8c0,0.1-0.1,0.6-0.2,1.4c-0.1,0.8-0.2,1.8-0.4,3c0.9,0,1.7-0.1,2.5-0.1h2.1
	c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.3-0.7,0.3H226c-0.2,1.3-0.4,2.7-0.5,4.3
	c-0.2,1.5-0.3,3-0.5,4.6c-0.1,1.5-0.3,3-0.4,4.4c-0.1,1.4-0.1,2.7-0.1,3.8s0.1,2,0.2,2.6c0.1,0.6,0.4,1,0.7,1s0.6-0.2,1-0.7
	s0.8-1.1,1.2-1.8s0.8-1.6,1.2-2.5s0.8-1.9,1.1-2.8c0.4-0.9,0.7-1.7,1-2.5c0.3-0.8,0.5-1.4,0.7-1.9c0.1-0.2,0.2-0.3,0.3-0.4
	s0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.3,0.2C232.4,30.3,232.4,30.4,232.4,30.6 M134.9,36.3c0.8,0,1.6-0.2,2.2-0.6
	c0.7-0.4,1.2-0.9,1.7-1.4c0.5-0.6,0.8-1.2,1.1-1.8c0.3-0.6,0.4-1.2,0.5-1.8c0.1-0.6,0-1-0.1-1.4c-0.2-0.4-0.4-0.5-0.8-0.5
	s-0.9,0.2-1.4,0.7c-0.5,0.4-0.9,1-1.4,1.8c-0.4,0.7-0.8,1.5-1.2,2.4C135.3,34.5,135,35.4,134.9,36.3 M121.1,37.9
	c-0.8,0.3-1.4,0.7-1.9,1.1c-0.5,0.4-0.8,0.8-1,1.2s-0.3,0.7-0.3,1s0.1,0.5,0.3,0.6c0.3,0.2,0.6,0.2,0.8-0.1c0.3-0.2,0.5-0.6,0.8-1
	c0.2-0.4,0.5-0.9,0.7-1.5C120.8,38.8,121,38.3,121.1,37.9 M195.5,38.7c-0.2,0-0.4,0-0.5,0.1h-0.5c-1.6,0-2.9-0.3-3.7-0.9
	c-0.9-0.6-1.5-1.4-1.9-2.3c-0.2,0.9-0.2,1.7-0.2,2.5s0.1,1.5,0.3,2.2c0.2,0.6,0.5,1.1,1,1.5c0.4,0.4,1,0.6,1.7,0.6
	c0.9,0,1.7-0.3,2.4-1C194.5,40.6,195.1,39.7,195.5,38.7 M196.5,33.7c0-1.1,0-2-0.2-2.9s-0.5-1.6-0.8-2.2c-0.4-0.6-0.9-0.8-1.5-0.8
	c-0.5,0-1,0.2-1.4,0.5c-0.3,0.4-0.5,0.8-0.8,1.4c-0.2,0.6-0.4,1.2-0.5,1.8c-0.1,0.7-0.2,1.3-0.2,2c0,0.6,0.1,1.2,0.4,1.8
	c0.2,0.5,0.6,1,1.1,1.3s1.1,0.5,1.9,0.5h0.8c0.3,0,0.5-0.1,0.8-0.1C196.3,35.8,196.5,34.8,196.5,33.7 M209.6,40.9
	c0-0.6-0.2-1.1-0.5-1.6c-0.4-0.5-0.8-0.9-1.3-1.4c-1.5,0.3-2.6,0.8-3.4,1.5c-0.8,0.7-1.2,1.4-1.3,2.1c-0.1,0.7,0.2,1.4,0.8,1.9
	s1.5,0.8,2.8,0.8c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.5,0.9-0.8s0.4-0.7,0.5-1.1C209.5,41.6,209.6,41.3,209.6,40.9"
    />
  </svg>
);

export default Superhost;

import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";

const StyledCallToAction = styled.div`
  position: relative;
  display: block;
  height: 100%;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 40px;
  }

  &:before {
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${color.primary.base};
    opacity: 0.9;
    z-index: 1;
  }
`;

const StyledBgImageWrapper = styled.div`
  transition: transform 0.2s ease-in-out;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  z-index: 0;
`;

const StyledBgImage = styled.img`
  transition: all 0.2s ease-in-out;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transform: scale(1);
`;

const StyledTitle = styled.h3`
  color: white;
  line-height: 1.6rem;
  font-size: 18px;

  @media only screen and (min-width: 991px) {
    font-size: 26px;
  }
`;

const StyledText = styled.span`
  color: white;
  margin-top: 10px;
  display: block;

  a {
    color: white;
    text-decoration: none;
  }
`;

const StyledTextWrapper = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
`;

const CallToAction = ({ className, image, title, text }) => (
  <StyledCallToAction className={className}>
    <StyledBgImageWrapper className="bg-img-wrapper">
      <StyledBgImage src={image} />
    </StyledBgImageWrapper>
    <StyledTextWrapper>
      <StyledTitle>{title}</StyledTitle>
      {text && <StyledText>{text}</StyledText>}
    </StyledTextWrapper>
  </StyledCallToAction>
);

export default CallToAction;
